// 這裡放一些共用的設定值

const tagKeyName = '空間風格';
const cityLocationClassify = {
    '北部': [1, 2, 7, 17, 3, 8, 9],
    '中部': [10, 4, 11, 12, 13],
    '南部': [15, 14, 5, 6, 16],
    '東部': [18, 19],
    '離島': [20, 21, 22]
};

const topCityList = [
    {
        name: '台北市',
        imgName: '台北市',
        cityId: 1
    },
    {
        name: '台中市',
        imgName: '台中市',
        cityId: 4
    },
    {
        name: '新北市',
        imgName: '新北市',
        cityId: 2
    },
    {
        name: '高雄市',
        imgName: '高雄市',
        cityId: 6
    }
]

/*
id img 對照文件
* https://docs.google.com/spreadsheets/d/1L9g60lrBQf8hMkEBxsL7010_LvAIRZwobmcDAYBMfMs/edit#gid=0
* */
const categoryIdImgDev = {
    "5": "meeting_seminar_classroom",
    "7": "private_office",
    "6": "shared_seat",
    "20": "gathering_space",
    "24": "salon_beauty_nails",
    "9": "event_space",
    "8": "sports_space",
    "30": "rental_studio",
    "22": "cafe_restaurant",
    "77": "kitchen",
    "13": "photo_studio",
    "28": "gym",
    "29": "sauna",
    "14": "board_game",
    "11": "art_performance_space",
    "39": "ktv_party_club",
    "23": "other_type",
    "49": "meeting_lecture",
    "50": "course_training",
    "51": "gathering_party",
    "52": "event",
    "53": "shooting_recording",
    "54": "sports_fitness",
    "55": "beauty_grooming",
    "56": "music_drama",
    "57": "hobby_handcraft",
    "58": "art_activity",
    "59": "other_usage"
}
const categoryIdImgProd = {
    "5": "meeting_seminar_classroom",
    "7": "private_office",
    "6": "shared_seat",
    "44": "gathering_space",
    "11": "salon_beauty_nails",
    "9": "event_space",
    "45": "sports_space",
    "46": "rental_studio",
    "8": "cafe_restaurant",
    "12": "kitchen",
    "13": "photo_studio",
    "48": "gym",
    "49": "sauna",
    "14": "board_game",
    "20": "art_performance_space",
    "10": "ktv_party_club",
    "15": "other_type",
    "50": "meeting_lecture",
    "51": "course_training",
    "52": "gathering_party",
    "53": "event",
    "54": "shooting_recording",
    "55": "sports_fitness",
    "56": "beauty_grooming",
    "57": "music_drama",
    "58": "hobby_handcraft",
    "59": "art_activity",
    "60": "other_usage"
}

const articleUrlToId = {
    'meeting-types-and-place-rentals': '117',
    'black-and-white-chef-restaurant-guide': '115',
    'place-rental-guide': "114",
    'how-to-find-the-perfect-place': '113',
    'jur-pace-venue-overview': '111',
    'babys-first-birthday-ceremony': '110',
    'dining-and-event-gathering-recommendations': '107',
    'board-game-place': '104',
    'locations-for-coser-photoshoots': '102',
    'studios-hoping-u': '95',
    'perfect-display-place': '91',
    'photography-studio-rental': '89',
    'future-trends-in-office': '41',
    'black-myth-wukong': '40',
    'mid-autumn-bbq-place': '39'
}

const blogArticleList = [
    {id: '117', slug: 'meeting-types-and-space-rentals'},
    {id: '115', slug: 'bblack-and-white-chef-shared-kitchen'},
    {id: '114', slug: 'place-rental-guide'},
    {id: '113', slug: 'how-to-find-the-perfect-place'},
    {id: '111', slug: 'jur-pace-venue-overview'},
    {id: '110', slug: 'babys-first-birthday-ceremony'},
    {id: '107', slug: 'dining-and-gathering-place'},
    {id: '104', slug: 'board-game-place'},
    {id: '102', slug: 'coser-photoshoots-place'},
    {id: '95', slug: 'studios-hoping-u'},
    {id: '91', slug: 'perfect-display-place'},
    {id: '89', slug: 'photography-studio-rental'},
    {id: '41', slug: 'future-trends-in-office'},
    {id: '40', slug: 'black-myth-wukong'},
    {id: '39', slug: 'mid-autumn-bbq-place'}
]


export {cityLocationClassify, tagKeyName, topCityList, categoryIdImgDev, categoryIdImgProd, blogArticleList};
