import { default as accountIWnKuvcAuMMeta } from "/app/pages/account.vue?macro=true";
import { default as _91spaceid_93jw63XISOSaMeta } from "/app/pages/admin-photo/[spaceid].vue?macro=true";
import { default as _91spaceid_93g2Up8LroP0Meta } from "/app/pages/admin-space/[spaceid].vue?macro=true";
import { default as _91adminID_93mlTBb7cJAEMeta } from "/app/pages/admin/[adminID].vue?macro=true";
import { default as _91id_93AtkXnaxz9hMeta } from "/app/pages/banner/[id].vue?macro=true";
import { default as _91id_93SEmTCtJI2KMeta } from "/app/pages/blog/[id].vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as forgotNziWY5IfaeMeta } from "/app/pages/forgot.vue?macro=true";
import { default as google_45returnWQfBxtF8ZbMeta } from "/app/pages/google-return.vue?macro=true";
import { default as homepagezHpgfakJ4NMeta } from "/app/pages/host/homepage.vue?macro=true";
import { default as plan3rmTvwUwhuMeta } from "/app/pages/host/plan.vue?macro=true";
import { default as qa8XZh4CdNidMeta } from "/app/pages/host/qa.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as completed6OMJkDb4CAMeta } from "/app/pages/order/completed.vue?macro=true";
import { default as in_45progressBh0uANlANsMeta } from "/app/pages/order/in-progress.vue?macro=true";
import { default as indexCICQ7TchH0Meta } from "/app/pages/order/index.vue?macro=true";
import { default as not_45startwm8DF5bTboMeta } from "/app/pages/order/not-start.vue?macro=true";
import { default as pendingpXoSqkpyR7Meta } from "/app/pages/order/pending.vue?macro=true";
import { default as refundedYhLC3eonI7Meta } from "/app/pages/order/refunded.vue?macro=true";
import { default as orderBjfxujOe1OMeta } from "/app/pages/order.vue?macro=true";
import { default as phone_45validationbhszKfgAV0Meta } from "/app/pages/phone-validation.vue?macro=true";
import { default as owner_45servicetCocp5jdUvMeta } from "/app/pages/policies/owner-service.vue?macro=true";
import { default as privacybuTHLA3lRDMeta } from "/app/pages/policies/privacy.vue?macro=true";
import { default as registermu8R7rSP5uMeta } from "/app/pages/register.vue?macro=true";
import { default as reserve_45returnfcYoM7v0ewMeta } from "/app/pages/reserve-return.vue?macro=true";
import { default as reserverdds3adYbRMeta } from "/app/pages/reserve.vue?macro=true";
import { default as search_45resultBWlOF1JFdGMeta } from "/app/pages/search-result.vue?macro=true";
import { default as _91spaceid_93C9GxVfKNqkMeta } from "/app/pages/space/[spaceid].vue?macro=true";
export default [
  {
    name: "account",
    path: "/account",
    meta: accountIWnKuvcAuMMeta || {},
    component: () => import("/app/pages/account.vue").then(m => m.default || m)
  },
  {
    name: "admin-photo-spaceid",
    path: "/admin-photo/:spaceid()",
    component: () => import("/app/pages/admin-photo/[spaceid].vue").then(m => m.default || m)
  },
  {
    name: "admin-space-spaceid",
    path: "/admin-space/:spaceid()",
    component: () => import("/app/pages/admin-space/[spaceid].vue").then(m => m.default || m)
  },
  {
    name: "admin-adminID",
    path: "/admin/:adminID()",
    component: () => import("/app/pages/admin/[adminID].vue").then(m => m.default || m)
  },
  {
    name: "banner-id",
    path: "/banner/:id()",
    component: () => import("/app/pages/banner/[id].vue").then(m => m.default || m)
  },
  {
    name: "blog-id",
    path: "/blog/:id()",
    component: () => import("/app/pages/blog/[id].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "forgot",
    path: "/forgot",
    meta: forgotNziWY5IfaeMeta || {},
    component: () => import("/app/pages/forgot.vue").then(m => m.default || m)
  },
  {
    name: "google-return",
    path: "/google-return",
    component: () => import("/app/pages/google-return.vue").then(m => m.default || m)
  },
  {
    name: "host-homepage",
    path: "/host/homepage",
    component: () => import("/app/pages/host/homepage.vue").then(m => m.default || m)
  },
  {
    name: "host-plan",
    path: "/host/plan",
    component: () => import("/app/pages/host/plan.vue").then(m => m.default || m)
  },
  {
    name: "host-qa",
    path: "/host/qa",
    component: () => import("/app/pages/host/qa.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: orderBjfxujOe1OMeta?.name,
    path: "/order",
    meta: orderBjfxujOe1OMeta || {},
    component: () => import("/app/pages/order.vue").then(m => m.default || m),
    children: [
  {
    name: "order-completed",
    path: "completed",
    component: () => import("/app/pages/order/completed.vue").then(m => m.default || m)
  },
  {
    name: "order-in-progress",
    path: "in-progress",
    component: () => import("/app/pages/order/in-progress.vue").then(m => m.default || m)
  },
  {
    name: "order",
    path: "",
    component: () => import("/app/pages/order/index.vue").then(m => m.default || m)
  },
  {
    name: "order-not-start",
    path: "not-start",
    component: () => import("/app/pages/order/not-start.vue").then(m => m.default || m)
  },
  {
    name: "order-pending",
    path: "pending",
    component: () => import("/app/pages/order/pending.vue").then(m => m.default || m)
  },
  {
    name: "order-refunded",
    path: "refunded",
    component: () => import("/app/pages/order/refunded.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "phone-validation",
    path: "/phone-validation",
    component: () => import("/app/pages/phone-validation.vue").then(m => m.default || m)
  },
  {
    name: "policies-owner-service",
    path: "/policies/owner-service",
    component: () => import("/app/pages/policies/owner-service.vue").then(m => m.default || m)
  },
  {
    name: "policies-privacy",
    path: "/policies/privacy",
    component: () => import("/app/pages/policies/privacy.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "reserve-return",
    path: "/reserve-return",
    meta: reserve_45returnfcYoM7v0ewMeta || {},
    component: () => import("/app/pages/reserve-return.vue").then(m => m.default || m)
  },
  {
    name: "reserve",
    path: "/reserve",
    meta: reserverdds3adYbRMeta || {},
    component: () => import("/app/pages/reserve.vue").then(m => m.default || m)
  },
  {
    name: "search-result",
    path: "/search-result",
    component: () => import("/app/pages/search-result.vue").then(m => m.default || m)
  },
  {
    name: "space-spaceid",
    path: "/space/:spaceid()",
    component: () => import("/app/pages/space/[spaceid].vue").then(m => m.default || m)
  }
]